@mixin icomoon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.search-result {
  &__form {
    width: 100%;
    margin: 20px auto;
    border-radius: 3px;

    display: flex;

    background: #FFFFFF;

    border: 1px solid #E3E3E3;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    border-radius: 4px;

    &.active {
      .search-result__label {
        display: flex;
      }
      .search-result__input {
        display: none;
      }
      .search-result__close-button {
        display: block;
      }
    }
  }

  &__label {
    height: 48px;
    width: calc(100% - 48px);
    display: none;
    align-items: center;
    padding-left: 20px;
  }


  &__search-extra-text {

    @media screen and (max-width: 320px) {
      display: none;
    }

    @media screen and (min-width: 400px) AND (max-width: 768px) {
      font-size: 12px;
      margin-left: 4px;
      margin-right: auto;
    }

    @media screen and (min-width: 320px) AND (max-width: 400px) {
      font-size: 12px;
      margin: auto;
    }

    @media screen and (min-width: 768px){
      font-size: 16px;
      margin-left: 7px;
    }


  }

  &__input {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    border-radius: 3px 0px 0px 3px;
    border: 0;



    color: #7B7B7B;
    height: 48px;
    width: calc(100% - 48px);

    padding-left: 20px;
  }

  &__close-button {
    min-width: 48px;
    width: 48px;
    height: 48px;
    background: transparent;
    border: 0;
    color: #7B7B7B;
    font-size: 20px;
    cursor: pointer;
    display: none;
  }

  &__button {
    cursor: pointer;
    min-width: 48px;
    width: 48px;
    height: 48px;
    background: #EB0015;
    border-radius: 0px 3px 3px 0px;
    border: 0;
    color: #fff;
    font-size: 20px;
  }

  &__total {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    /* identical to box height, or 112% */
    color: #605F5F;
  }

  &__card {
    width: 100%;
    background: #FFFFFF;
    /* Black/300 */

    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    border-radius: 4px;

    margin-bottom: 20px;
    padding: 20px;
  }

  &__card-image {
    width: 100%;
    height: auto;
    border-radius: 4px;
    margin-bottom: 20px;

    @media screen and (min-width: 992px) {
      margin-bottom: 0;
      width: 245px;
      height: 128px;
    }
  }

  &__card-body {
    @media screen and (min-width: 992px) {
      padding-left: 20px;
    }
  }

  &__card-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    /* or 111% */

    /* Black/700 (Base) */
    color: #3F3B3B;

    margin-bottom: 10px;
  }

  &__card-description {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    /* or 150% */

    color: #939393;

    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__card-tag {
    &--primary {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 21px;
      color: #EB0015;
      text-decoration: underline;
    }

    &--secondary {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 21px;
      color: #C4C4C4;
    }
  }
}
